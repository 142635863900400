import React from "react"
import { useTranslation } from "react-i18next"

import { DateFormat } from "components/common/Format/DateFormat"
import { PriceRateTypePublicDisplay } from "components/front/PMS/PriceRate/Type/Display/PriceRateTypePublicDisplay"
import { ContactDisplay } from "components/front/PMS/Contact/Display/ContactDisplay"
import { ConfirmationAnswerDisplay } from "components/front/PMS/Confirmation/Answer/Display/ConfirmationAnswerDisplay"
import { Confirmation, ContactTypeEnum } from "model"

import "./style.scss"
import { PriceFormat } from "components/common/Format/PriceFormat"
import { ConfirmationDataDisplay } from "components/front/PMS/Confirmation/Data/Display/ConfirmationDataDisplay"
import { ConfirmationEventDisplay } from "components/front/PMS/Confirmation/Event/ConfirmationEventDisplay"

type ConfirmationPublicDetailsProps = {
  confirmation: Confirmation,
}


export const ConfirmationPublicDetails = (props: ConfirmationPublicDetailsProps) => {

  const confirmation: Confirmation = props?.confirmation
  const reservation = confirmation?.Reservation
  const reservationGroup = confirmation?.ReservationGroup
  const wholeDates = reservationGroup?.wholeDates
  const Hotel = reservation?.Hotel || reservationGroup?.Hotel
  const Contact = confirmation?.Contact
  const isGuest = Contact?.type === ContactTypeEnum.GUEST
  const isGroup = !!reservationGroup
  const reservationList = isGroup && reservationGroup?.ReservationGroupLinkConfirmed?.map(rgl => rgl.Reservation)
  const isReservationInGroupHaveSameDate = isGroup && reservationList?.reduce((acc, current, index) => {
    const previous = reservationList[index - 1]
    return index === 0 ? true : acc ? current.arrivalDate === previous?.arrivalDate && current.departureDate === previous.departureDate : false
  }, true)

  // console.log("isReservationInGroupHaveSameDate", isReservationInGroupHaveSameDate)

  const answerList = confirmation?.Answer
  const hasAnswers = answerList?.length > 0

  const displayRoomCategoryInDetails = Hotel.displayRoomCategoryInDetails

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'confirmation' });
  const isFrench = i18n.language === "fr"

  const groupData: { optionGroupList: [any] } = isGroup && reservationList?.reduce((acc: any, current: any) => {
    return {
      ...acc,
      totalPrice: acc.totalPrice + current?.totalIncTax,
      optionGroupList: [...acc.optionGroupList, ...current?.ReservationPricePackage],
      nbPeople: acc.nbPeople + current?.npPeople
    }
  }, {
    totalPrice: 0,
    optionGroupList: [],
  })

  const optionList = isGroup ? groupData?.optionGroupList : reservation?.ReservationPricePackage

  // @ts-ignore
  const optionListFiltered: any = optionList?.reduce((acc: any, option, index: number) => {
    if (option?.Option?.canBeDisplayedInConfirmation === false) return acc
    return {
      ...acc,
      [option.PLUCode]: {
        ...option,
        quantity: (acc[option.PLUCode]?.quantity || 0) + Number(option.quantity),
        ammountIncVat: (acc[option.PLUCode]?.ammountIncVat || 0) + Number(option.ammountIncVat),
      }
    }
  }, {})
  const hasOptions = Object.values(optionListFiltered)?.length > 0
  // const hasData = !!confirmation?.confirmationData
  const hasServiceEvents = confirmation?.ServiceEvent?.length > 0

  const totalPrice = isGroup ? reservationGroup?.totalIncTax : reservation?.totalIncTax
  // const hasManyPackages = reservation?.ReservationRate?.length > 0
  const hasManyPackages = reservation?.ReservationRate?.reduce((acc, rRate, index) => {
    const rRateBefore = reservation?.ReservationRate[index - 1]
    if (index === 0) {
      return acc
    };
    if (acc) {
      return true
    }
    if (!acc) {
      return rRate?.PriceRateType?.id !== rRateBefore?.PriceRateType?.id
    }
    return false
  }, false)

  const defaultRate = reservation?.DefaultRate || reservationGroup?.DefaultRate
  const hasTotal = totalPrice > 0

  console.log("defaultRate", defaultRate)

  return <div className="confirmation-public-details-container">
    <h1>{t("Votre réservation")}</h1>
    <div className="card card-body">
      <div>
        <table className="table table-bordered table-stripped">
          <tbody>
            <tr>
              <td colSpan={2}>
                <span>{Hotel?.name}</span><br />
                <span>{Hotel?.address}</span><br />
                <span>{Hotel?.phoneNumber}</span>
              </td>
            </tr>
            <tr>
              <th>{t("Réservation")}</th>
              {!isGroup && <td>#{reservation?.pmsId}</td>}
              {isGroup && <td>{reservationList.map((reservation) => {
                return <span key={`key_ID_${reservation?.id}`}>#{reservation?.pmsId} </span>
              })}</td>}
            </tr>
            <tr>
              <th>{t("Nom")}</th>
              <td>
                <ContactDisplay contact={Contact} />
                {!isGuest && reservation?.ReservationGuest?.map((rGuest: any) => {
                  return <div><ContactDisplay contact={rGuest?.Contact} /></div>
                })}
              </td>
            </tr>
            {!isGroup && <tr>
              <th>{t("Arrivée")}</th>
              <td>
                <DateFormat value={new Date(reservation?.arrivalDate || reservationGroup?.arrivalDate)} showUTC UTCOffset />
                <span> {Hotel?.checkInHour}</span>
              </td>
            </tr>}
            {!isGroup && <tr>
              <th>{t("Départ")}</th>
              <td>
                <DateFormat value={new Date(reservation?.departureDate || reservationGroup?.departureDate)} showUTC UTCOffset />
                <span> {Hotel?.checkOutHour}</span>
              </td>
            </tr>}
            {isReservationInGroupHaveSameDate && <tr>
              <th>{t("Arrivée")}</th>
              <td>
                <DateFormat value={new Date(wholeDates?.arrivalDate)} showUTC UTCOffset />
                {/* <DateFormat value={new Date(reservation?.arrivalDate || reservationGroup?.arrivalDate)} showUTC UTCOffset /> */}
                <span> {Hotel?.checkInHour}</span>
              </td>
            </tr>}
            {isReservationInGroupHaveSameDate && <tr>
              <th>{t("Départ")}</th>
              <td>
                <DateFormat value={new Date(wholeDates?.departureDate)} showUTC UTCOffset />
                {/* <DateFormat value={new Date(reservation?.departureDate || reservationGroup?.departureDate)} showUTC UTCOffset /> */}
                <span> {Hotel?.checkOutHour}</span>
              </td>
            </tr>}
            <tr>
              <th>{t("Nombre de personnes")}</th>
              {isGroup ? <td>{reservationGroup?.totalPeople}</td> : <td>
                <div>{`${reservation?.nbAdults} ${t("adultes", { count: reservation?.nbAdults })}`}</div>
                <div>{(reservation?.nbChildren > 0 || reservation?.nbSpecial > 0) ? `${reservation?.nbChildren + reservation?.nbSpecial} ${t("enfants", { count: reservation?.nbChildren + reservation?.nbSpecial })}` : ""}</div>
              </td>}
            </tr>
            {!isGroup && <tr>
              <th>{t("Nombre de nuits")}</th>
              <td>{reservation?.nbNight}</td>
            </tr>}
            {(!isGroup && displayRoomCategoryInDetails) && <tr>
              <th>{t("Catégorie de Chambre")}</th>
              <td><span style={{ textTransform: "capitalize" }}>{isFrench ? reservation?.ReservationRoom?.Room?.RoomType?.labelFr?.toLowerCase() : reservation?.ReservationRoom?.Room?.RoomType?.labelEn?.toLowerCase()}</span></td>
            </tr>}
            {isGroup && <tr>
              <td colSpan={2} align='center'>
                <div className="card-columns flex-only-sm">
                  {isGroup && reservationList.map((reservation, index: number) => {

                    const isGuestTheSame = reservation?.ReservationGuest?.length === 1 && reservation?.ReservationGuest[0].contactId === confirmation?.Contact?.id

                    const displayGuest = !isGuestTheSame


                    return <div key={`key_details_${reservation?.id}`}>
                      <div className="card">
                        <div className="card-header text-white" style={{ backgroundColor: "var(--theme-color)" }}>
                          <span style={{ textTransform: "capitalize" }}>{t("chambre")?.toLowerCase()} {index + 1}</span> #{reservation?.pmsId}
                        </div>
                        <div className="card-body">
                          <table className="table table-bordered table-stripped">
                            <tbody>
                              {displayRoomCategoryInDetails && <tr>
                                <th>{t("Catégorie de Chambre")}</th>
                                <td><span style={{ textTransform: "capitalize" }}>{isFrench ? reservation?.ReservationRoom?.Room?.RoomType?.labelFr?.toLowerCase() : reservation?.ReservationRoom?.Room?.RoomType?.labelEn?.toLowerCase()}</span></td>
                              </tr>}
                              {displayGuest && <tr>
                                <th>{t("Nom")}</th>
                                <td>
                                  {reservation?.ReservationGuest?.map((rGuest) => {
                                    const isGuest = rGuest?.Contact?.type === ContactTypeEnum.GUEST
                                    return <div>
                                      {isGuest && <ContactDisplay contact={rGuest?.Contact} />}
                                    </div>
                                  })}
                                </td>
                              </tr>}
                              <tr>
                                <th>{t("Nombre de personnes")}</th>
                                <td>{reservation?.npPeople}</td>
                              </tr>
                              {!isReservationInGroupHaveSameDate && <tr>
                                <th>{t("Arrivée")}</th>
                                <td>
                                  <DateFormat value={new Date(reservation?.arrivalDate || reservationGroup?.arrivalDate)} showUTC UTCOffset />
                                  <span> {Hotel?.checkInHour}</span>
                                </td>
                              </tr>}
                              {!isReservationInGroupHaveSameDate && <tr>
                                <th>{t("Départ")}</th>
                                <td>
                                  <DateFormat value={new Date(reservation?.departureDate || reservationGroup?.departureDate)} showUTC UTCOffset />
                                  <span> {Hotel?.checkOutHour}</span>
                                </td>
                              </tr>}
                              <tr>
                                <th>{t("Package")}</th>
                                <td><span style={{ textTransform: "capitalize" }}>{isFrench ? reservation?.DefaultRate?.PriceRateType?.labelFr?.toLowerCase() : reservation?.DefaultRate?.PriceRateType?.labelEn?.toLowerCase()}</span></td>
                              </tr>
                              <tr>
                                <th>{t("Prix")}</th>
                                <td>{reservation?.totalIncTax} €</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  })}
                </div>
              </td>
            </tr>}
            {hasManyPackages && <tr>
              <th>{t("packages")}</th>
              <td>
                {reservation?.ReservationRate?.map((rRate, index) => {
                  const rateType = rRate?.PriceRateType
                  return <table key={`rrType_${rRate}`} className="table table-bordered">
                    <tbody>
                      <tr>
                        <th colSpan={2}>{t("night", { index: index + 1 })}</th>
                      </tr>
                      <tr>
                        <td>{t("package")}</td>
                        <td>
                          {isFrench ? rateType?.labelFr : rateType?.labelEn}
                        </td>
                      </tr>
                      <PriceRateTypePublicDisplay
                        isFrench={isFrench}
                        priceRateType={rateType}>
                        {(hasExtra, extra) => {
                          return hasExtra && <tr>
                            <th>{t("prestations.included")}</th>
                            <td>{extra}</td>
                          </tr>
                        }}
                      </PriceRateTypePublicDisplay>

                    </tbody>
                  </table>

                })}
              </td>
            </tr>}
            {(!isGroup && !hasManyPackages) && <tr>
              <th>{t("package")}</th>
              <td><span style={{ textTransform: "capitalize" }}>{isFrench ? reservation?.DefaultRate?.PriceRateType?.labelFr?.toLowerCase() : reservation?.DefaultRate?.PriceRateType?.labelEn?.toLowerCase()}</span></td>
            </tr>}
            {(defaultRate && !hasManyPackages) && <PriceRateTypePublicDisplay priceRateType={defaultRate?.PriceRateType} isFrench={isFrench}>
              {(hasExtra, extra) => {
                return hasExtra && <tr>
                  <th>{t("prestations.included")}</th>
                  <td>{extra}</td>
                </tr>
              }}
            </PriceRateTypePublicDisplay>}
            {hasServiceEvents && <tr>
              <th>{t("events")}</th>
              <td>
                <ConfirmationEventDisplay serviceEventList={confirmation?.ServiceEvent} />
              </td>
            </tr>}
            <ConfirmationDataDisplay confirmationData={confirmation?.confirmationData} isFrench={isFrench} />
            {hasAnswers && <tr>
              <th>{t("remarks")}</th>
              <td>
                <ConfirmationAnswerDisplay answerList={answerList} isFrench={isFrench} />
              </td>
            </tr>}
            {hasOptions && <tr>
              <th>{t("options_included")}</th>
              <td>
                <table className="table table-stripped">
                  <tbody>
                    {optionListFiltered && Object.keys(optionListFiltered).map((key: any) => {
                      const option: any = optionListFiltered[key]
                      return <tr key={option?.id}>
                        <td>{isFrench ? option?.Option?.labelFr : option?.Option?.labelEn} {option?.ammountIncVat === 0 ? `(${t("options_offered")})` : ""}</td>
                      </tr>
                    })}
                  </tbody>

                </table>
              </td>
            </tr>}

            {hasTotal && <tr>
              <th>
                <div className="d-flex">
                  <span>{t("Total Général")}</span>
                </div>
              </th>
              <td>
                {/* {totalPrice} € <span style={{fontSize: 10}}>{t("tax.excluded")}</span> */}
                <PriceFormat value={totalPrice * 100} withDecimal /> <span style={{ fontSize: 10 }}>{t("tax.excluded")}</span>
              </td>

            </tr>}
          </tbody>
        </table>

      </div>
    </div>
  </div>
}
import React from 'react';
import { Features } from './Features/Features';

export default function LandingPage() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <header className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="#" onClick={(e) => e.preventDefault()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="3" y1="9" x2="21" y2="9"></line>
              <line x1="9" y1="21" x2="9" y2="9"></line>
            </svg>
            <span className="font-weight-bold">CRM Pro</span>
          </a>
          <nav className="navbar-nav ml-auto">
            <a className="nav-link" href="#features" onClick={(e) => e.preventDefault()}>Features</a>
            <a className="nav-link" href="#pricing" onClick={(e) => e.preventDefault()}>Pricing</a>
            <a className="nav-link" href="#contact" onClick={(e) => e.preventDefault()}>Contact</a>
          </nav>
        </div>
      </header>
      <main className="flex-grow-1">
        <section className="py-5 py-md-7">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-md-10 col-lg-8">
                <h1 className="display-4 font-weight-bold mb-3">Supercharge Your Customer Relationships</h1>
                <p className="lead text-muted mb-4">
                  Streamline your sales process, boost customer engagement, and skyrocket your revenue with CRM Pro.
                </p>
                <div>
                  <button className="btn btn-primary mr-2">Get Started for Free</button>
                  <button className="btn btn-outline-secondary">
                    Book a Demo
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="ml-2" style={{ width: '1em', height: '1em' }}>
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="py-5 bg-light">
          <div className="container">
            <h2 className="text-center mb-5">Powerful Features</h2>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mb-3">
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <h5 className="card-title">Contact Management</h5>
                    <p className="card-text">Effortlessly organize and manage your customer contacts in one centralized database.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mb-3">
                      <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                      <line x1="16" y1="2" x2="16" y2="6"></line>
                      <line x1="8" y1="2" x2="8" y2="6"></line>
                      <line x1="3" y1="10" x2="21" y2="10"></line>
                    </svg>
                    <h5 className="card-title">Task & Appointment Scheduling</h5>
                    <p className="card-text">Never miss a follow-up with integrated task management and appointment scheduling.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mb-3">
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <h5 className="card-title">Email Integration</h5>
                    <p className="card-text">Seamlessly integrate with your email client for efficient communication tracking.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Features />
        <section id="pricing" className="py-5">
          <div className="container">
            <h2 className="text-center mb-5">Simple, Transparent Pricing</h2>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title">Starter</h5>
                    <h1 className="card-text mb-0">$29</h1>
                    <p className="text-muted">per month</p>
                    <ul className="list-unstyled mt-4">
                      <li>Up to 1,000 contacts</li>
                      <li>Basic reporting</li>
                      <li>Email support</li>
                    </ul>
                    <button className="btn btn-primary btn-block mt-4">Get Started</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title">Professional</h5>
                    <h1 className="card-text mb-0">$79</h1>
                    <p className="text-muted">per month</p>
                    <ul className="list-unstyled mt-4">
                      <li>Up to 10,000 contacts</li>
                      <li>Advanced reporting</li>
                      <li>24/7 phone support</li>
                    </ul>
                    <button className="btn btn-primary btn-block mt-4">Get Started</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <h5 className="card-title">Enterprise</h5>
                    <h1 className="card-text mb-0">Custom</h1>
                    <p className="text-muted">Contact us for pricing</p>
                    <ul className="list-unstyled mt-4">
                      <li>Unlimited contacts</li>
                      <li>Custom reporting</li>
                      <li>Dedicated account manager</li>
                    </ul>
                    <button className="btn btn-primary btn-block mt-4">Contact Sales</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="py-5 bg-light">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-md-8">
                <h2 className="mb-3">Ready to Get Started?</h2>
                <p className="lead text-muted mb-4">
                  Take your customer relationships to the next level with CRM Pro. Sign up for a free trial today.
                </p>
                <button className="btn btn-primary btn-lg">Book a Demo</button>
                <p className="mt-2 text-muted small">
                  No credit card required. 14-day free trial.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="py-3 bg-light">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center text-md-left">
              <p className="mb-0 small text-muted">© 2023 CRM Pro. All rights reserved.</p>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <a className="text-muted small mr-3" href="#" onClick={(e) => e.preventDefault()}>Terms of Service</a>
              <a className="text-muted small" href="#" onClick={(e) => e.preventDefault()}>Privacy</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}



import React from "react"
import { PreStay, PreStayResponseStatusEnum, PreStaySendStatusEnum } from "model"
import { Tooltip } from "components/front/APP/Navigation/Tooltip"

export type PreStayResponseStatusProps = {
  preStay: PreStay
}

export const PreStayResponseStatus = ({ preStay }: PreStayResponseStatusProps) => {


  switch (preStay?.responseStatus) {
    case PreStayResponseStatusEnum.PRE_STAY_RESPONSE_STATUS_TO_ANSWER: {
      return <span className="badge badge-sm badge-warning">NON REPONDU</span>

    }

    case PreStayResponseStatusEnum.PRE_STAY_RESPONSE_STATUS_ANSWERED: {
      return <span className="badge badge-sm badge-success">REPONDU</span>
    }

    default: {
      return <span className="badge badge-sm badge-dard">{preStay?.sendStatus}</span>
    }

  }


}
import gql from "graphql-tag";

export default gql`

  fragment PreStayConfigFragment on PreStayConfig {
    id
    dest
    preStayActive
    demoMode
    templateId
    eventTemplateId
    nbDaysBeforeArrival
    sendHour
    channel
    
  }

`
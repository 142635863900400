import { PreStayConfigInput, Template } from "model"
import React from "react"
import { BooleanInput, NumberInput, SelectInput } from "@zipou/front_tools"
import { useQuery } from "@apollo/react-hooks"
import templateListQuery from "graphql/APP/Template/query/templateList"

export type PreStayConfigFormProps = {
  preStayConfig: PreStayConfigInput,
  hotelId: string,
  errors: any,
  onChange: (preStayConfig: PreStayConfigInput) => void,
}

export const PreStayConfigForm = ({ preStayConfig, hotelId, errors, onChange }: PreStayConfigFormProps) => {


  const { data } = useQuery<{ list: Template[] }>(templateListQuery, {
    variables: {
      hotelId,
    }
  })
  const templateList = data?.list

  return <div>
    <BooleanInput id={"preStayActive"} value={preStayConfig?.preStayActive} label='Pre Stay Actif ?' errors={errors} onChange={(v) => {
      onChange({
        ...preStayConfig,
        preStayActive: v,
      })
    }} />


    <BooleanInput id={"demoMode"} value={preStayConfig?.demoMode} label='Mode DEMO ?' errors={errors} onChange={(v) => {
      onChange({
        ...preStayConfig,
        demoMode: v,
      })
    }} />

    <NumberInput id={"nbDaysBeforeArrival"} value={`${preStayConfig?.nbDaysBeforeArrival}` || ""} label='Nombre de Jours avant arrivée' errors={errors} onChange={(e, v) => {
      onChange({
        ...preStayConfig,
        nbDaysBeforeArrival: Number(v),
      })
    }} />



    <NumberInput id={"sendHour"} value={`${preStayConfig?.sendHour}` || ""} label="Heure d'envoi" errors={errors} onChange={(e, v) => {
      onChange({
        ...preStayConfig,
        sendHour: v,
      })
    }} />



    <div className="input-group">
      <span className='input-group-text'>Copies des envois</span>
      <div className="form-control" style={{ height: "100%" }}>
        {preStayConfig?.dest?.map((dest: string, index: number) => {
          return <div className="input-group" key={`preStayConfig_dest_${index}`}>
            <span className="input-group-text">Email</span>
            <input className="form-control" type="text" value={dest} onChange={(e: any) => {
              onChange({
                ...preStayConfig,
                dest: preStayConfig.dest?.map((el: any, id: number) => (id === index) ? e.target.value : el)
              })
            }} />
            <span className="input-group-text">
              <span className="icon-close" onClick={() => {
                onChange({
                  ...preStayConfig,
                  dest: preStayConfig?.dest?.filter((el: any, id: number) => id !== index)
                })
              }}></span>
            </span>

          </div>
        })}
        <div className="">
          <button className="btn btn-dark btn-sm" onClick={() => {
            onChange({
              ...preStayConfig,
              dest: [
                ...(preStayConfig?.dest ? preStayConfig?.dest : []),
                ""
              ],
            })
          }}>Ajouter</button>
        </div>
      </div>
    </div>

    <div className="input-group">
      <span className='input-group-text'>Canaux</span>
      <div className="form-control" style={{ height: "100%" }}>
        <input type="checkbox" id="allowNull" checked={preStayConfig?.channel?.includes(null)} onChange={e => {
          onChange({
            ...preStayConfig,
            channel: e.target.checked ? [...(preStayConfig?.channel || []), null] : preStayConfig?.channel?.filter((el: any) => el !== null)
          })
        }} /><label htmlFor="allowNull">Autoriser un channel Vide</label>
        {preStayConfig?.channel
          ?.filter((el: any) => el !== null)
          ?.map((dest: string, index: number) => {
            return <div className="input-group" key={`confDest_${index}`}>
              {/* <span className="input-group-text">Channel</span> */}
              <input className="form-control" type="text" value={dest} onChange={(e: any) => {
                onChange({
                  ...preStayConfig,
                  channel: preStayConfig?.channel?.map((el: any, id: number) => (id === index) ? e.target.value : el)
                })
              }} />
              <span className="input-group-text">
                <span className="icon-close" onClick={() => {
                  onChange({
                    ...preStayConfig,
                    channel: preStayConfig?.channel?.filter((el: any, id: number) => id !== index)
                  })
                }}></span>
              </span>

            </div>
          })}
        <div className="">
          <button className="btn btn-dark btn-sm" onClick={() => {
            onChange({
              ...preStayConfig,
              channel: [
                ...(preStayConfig?.channel ? preStayConfig?.channel : []),
                ""
              ],
            })
          }}>Ajouter</button>
        </div>
      </div>
    </div>


    <SelectInput id={"templateId"} value={preStayConfig?.templateId} choiceList={templateList?.map(template => ({ id: template.id, label: template.name }))} label='Template' errors={errors} onChange={v => {
      onChange({
        ...preStayConfig,
        templateId: v,
      })
    }} />

    <SelectInput id={"eventTemplateId"} value={preStayConfig?.eventTemplateId} choiceList={templateList?.map(template => ({ id: template.id, label: template.name }))} label='Template de Services' errors={errors} onChange={v => {
      onChange({
        ...preStayConfig,
        eventTemplateId: v,
      })
    }} />

  </div>


}
import { useQuery } from "@apollo/react-hooks"
import { CameraClientModule } from "@zipou/video_front"
import { URL_VIDEO_DISPLAY, URL_VIDEO_PANEL_DISPLAY } from "constant/url"
import hotelGet from "graphql/PMS/Hotel/query/hotelGet"
import { Hotel } from "model"
import React from "react"
import { useHistory } from "react-router-dom"

type VideoDisplayProps = {
  hotelId: string,
}

export const VideoDisplay = ({ hotelId }: VideoDisplayProps) => {

  const history = useHistory()

  const { data } = useQuery<{ hotel: Hotel }>(hotelGet, {
    variables: {
      id: hotelId,
    },
    skip: !hotelId
  })


  const hotel = data?.hotel
  const tokenId = hotel?.VideoConfig?.tokenId
  const token = hotel?.VideoConfig?.token
  const hasCreds = tokenId && token

  return <div>
    {hasCreds && <CameraClientModule
      tokenId={tokenId}
      token={token}
      onPanelClick={(p) => window.open(URL_VIDEO_PANEL_DISPLAY.replace(":tokenId", tokenId).replace(":token", token).replace(":panelId", p.id))}
      moduleConfig={{
        url: "https://www.box4b.fr",
        wsUrl: "wss://www.box4b.fr/subscriptions",
      }}
    />}
    {!hasCreds && <div className="alert alert-danger">Aucune configuration trouvée</div>}
  </div>
}
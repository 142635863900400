import React from "react"
import {PreStayStatusEnum} from "model"

export type PreStayStatusProps = {
  status: PreStayStatusEnum
}

export const PreStayStatus = (props: PreStayStatusProps) => {


  switch(props?.status) {
    case PreStayStatusEnum.PRE_STAY_STATUS_INITIAL: {
      return <span className="badge badge-sm badge-secondary">EN ATTENTE</span>
    }

    default : {
      return <span className="badge badge-sm badge-dard">{props?.status}</span>
    }

  }


}
import { useQuery } from "@apollo/react-hooks"
import priceRateCancelConditionListGql from "graphql/PMS/PriceRate/CancelCondition/query/priceRateCancelConditionList.gql"
import React, { useState } from "react"

import "./style.scss"
import { BooleanInput, SelectInput, TextField, TextInput } from "@zipou/front_tools"
import { PriceRateTypeInput } from "model"

type PriceRateTypeFormProps = {
  open: boolean,
  hotelId: string,
  priceRateType: PriceRateTypeInput,
  errors?: any,
  onChange: (priceRateType: PriceRateTypeInput) => void,
}

export const PriceRateTypeForm = (props: PriceRateTypeFormProps) => {

  const { errors, hotelId, priceRateType } = props


  const isNew = !priceRateType?.code
  const [open, updateOpen] = useState(isNew || props?.open)


  const { data } = useQuery(priceRateCancelConditionListGql, {
    variables: {
      hotelId,
    }
  })

  const onChange = (data: any) => {
    return props?.onChange(data)
  }
  const hasErrors = errors && Object.keys(errors).length > 0

  return <div className="card price-rate-type-container">
    <div className={isNew ? "card-header bg-warning text-white" : "card-header"} onClick={() => updateOpen(!open)}>
      {hasErrors && <span className="icon-warning-sign"> </span>}
      {priceRateType?.code || "NOUVEAU CODE"}
    </div>
    <div className={open ? "collapse-container-price-rate-type collapse-visible-price-rate-type" : "collapse-container-price-rate-type"}>
      <div className="card-body">

        <TextInput value={priceRateType?.code} errors={props?.errors?.code} label="Code" onChange={(v) => {
          onChange({
            ...priceRateType,
            code: v,
          })
        }} />
        <div className="input-group">
          <span className="input-group-text">Nom</span>
          <div className="form-control" style={{ height: "100%" }}>
            <TextInput value={priceRateType?.labelFr} errors={props?.errors?.labelFr} label="Français" onChange={(v) => {
              onChange({
                ...priceRateType,
                labelFr: v,
              })
            }} />

            <TextInput value={priceRateType?.labelEn} errors={props?.errors?.labelEn} label="Anglais" onChange={(v) => {
              onChange({
                ...priceRateType,
                labelEn: v,
              })
            }} />
          </div>
          <span className="input-group-text">
            <span className='badge badge-warning'>Visible par les clients</span>
          </span>

        </div>
        <div className="input-group">
          <span className="input-group-text">Prestation Incluses</span>
          <div className="form-control" style={{ height: "100%" }}>
            <TextField label="Français" value={priceRateType?.descFr} isError={false} onChange={(value: string) => {
              onChange({
                ...priceRateType,
                descFr: value,
              })
            }} />

            <TextField label="Anglais" value={priceRateType?.descEn} isError={false} onChange={(value: string) => {
              onChange({
                ...priceRateType,
                descEn: value,
              })
            }} />
          </div>
          <span className="input-group-text">
            <span className='badge badge-warning'>Visible par les clients</span>
          </span>

        </div>



        <BooleanInput value={priceRateType?.isBreakfastIncluded} errors={props?.errors?.isBreakfastIncluded} label="Petit Dejeuner compris ?" onChange={value => {
          onChange({
            ...priceRateType,
            isBreakfastIncluded: value,
          })
        }} />

        <BooleanInput value={priceRateType?.isRefundable} errors={props?.errors?.isRefundable} label="Remboursable ?" onChange={value => {
          onChange({
            ...priceRateType,
            isRefundable: value,
          })
        }} />

        <BooleanInput value={priceRateType?.isGiftBox} errors={props?.errors?.isGiftBox} label="Coffret Cadeau ?" onChange={value => {
          onChange({
            ...priceRateType,
            isGiftBox: value,
          })
        }} />

        <BooleanInput value={priceRateType?.isGroup} errors={props?.errors?.isGroup} label="Code Groupe ?" onChange={value => {
          onChange({
            ...priceRateType,
            isGroup: value,
          })
        }} />

        <BooleanInput value={priceRateType?.mustGenerateConfirmation} errors={props?.errors?.mustGenerateConfirmation} label="Confirmation ?" onChange={value => {
          onChange({
            ...priceRateType,
            mustGenerateConfirmation: value,
          })
        }} />

        <BooleanInput label="Code Offert ?" value={priceRateType?.isFree} errors={props?.errors} onChange={v => {
          onChange({
            ...priceRateType,
            isFree: v,
          })
        }} />
        <BooleanInput label="Inclus dans le total des confirmations ?" value={priceRateType?.isIncludedInConfirmationTotal} errors={props?.errors} onChange={v => {
          onChange({
            ...priceRateType,
            isIncludedInConfirmationTotal: v,
          })
        }} />

        <BooleanInput id={"hasSpa"} label="Spa Inclus ?" errors={props?.errors} value={priceRateType?.hasSpa} onChange={(value: boolean) => {
          onChange({
            ...priceRateType,
            hasSpa: value,
          })
        }} />

        <BooleanInput id={"hasFood"} label="Restauration Incluse ?" errors={props?.errors} value={priceRateType?.hasFood} onChange={(value: boolean) => {
          onChange({
            ...priceRateType,
            hasFood: value,
          })
        }} />

        <BooleanInput id={"hasDrink"} label="Boisson incluses ?" errors={props?.errors} value={priceRateType?.hasDrink} onChange={(value: boolean) => {
          onChange({
            ...priceRateType,
            hasDrink: value,
          })
        }} />


        <BooleanInput id={"canBeSelectedInProposition"} label="Disponible dans les propositions ?" errors={props?.errors} value={priceRateType?.canBeSelectedInProposition} onChange={(value: boolean) => {
          onChange({
            ...priceRateType,
            canBeSelectedInProposition: value,
          })
        }} />

        <SelectInput id='priceRateCancelConditionId' label={"Conditions d'annulation"} disabled={false} choiceList={data?.list?.map((el: any) => ({ id: el.id, label: el.label }))} value={priceRateType?.priceRateCancelConditionId || ""} errors={props?.errors} onChange={(e) => {
          onChange({
            ...priceRateType,
            priceRateCancelConditionId: e,
          })
        }} />

      </div>
    </div>
  </div>

}

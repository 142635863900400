import { PanelViewModule } from "@zipou/video_front"
import React from "react"
import { useParams } from "react-router-dom"

export const VideoPanelDisplayRoute = () => {

  const env = process.env.NODE_ENV

  // const moduleConfig = env === "development" ? {
  //   url: "http://localhost:4001",
  //   wsUrl: "ws://localhost:4001",
  // } : {
  //   url: "https://www.box4b.fr",
  //   wsUrl: "wss://www.box4b.fr/subscriptions",
  // }


  const moduleConfig = {
    url: "https://www.box4b.fr",
    wsUrl: "wss://www.box4b.fr/subscriptions",
  }


  const params = useParams<any>()
  const { tokenId, token, panelId } = params
  const hasParams = !!tokenId && !!panelId && !!token


  console.log({ hasParams })

  return <div>
    {hasParams && <PanelViewModule
      tokenId={tokenId}
      token={token}
      panelId={panelId}
      moduleConfig={moduleConfig}
    />}
  </div>


}
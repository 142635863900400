import React from 'react';

export const Features = () => {
  return (
    <section className="py-5 bg-white">
      <div className="container">
        <h2 className="text-center mb-5">Fonctionnalités</h2>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th scope="col" className="w-25">Offre</th>
                <th scope="col" className="text-center">S</th>
                <th scope="col" className="text-center">M</th>
                <th scope="col" className="text-center">L</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CRM</td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
              </tr>
              <tr>
                <td>Confirmation</td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
              </tr>
              <tr>
                <td>Proposition</td>
                <td className="text-center">-</td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
              </tr>
              <tr>
                <td>Téléphonie</td>
                <td className="text-center">-</td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
              </tr>
              <tr>
                <td>Paiements</td>
                <td className="text-center">-</td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
              </tr>
              <tr>
                <td>Pre Post Stay / Checkin</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
              </tr>
              <tr>
                <td>Facturation / Checkout déportés</td>
                <td className="text-center">-</td>
                <td className="text-center">-</td>
                <td className="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-success" style={{ width: '1.2rem' }}>
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}


import React from "react"
import { PreStay, PreStaySendStatusEnum } from "model"
import { Tooltip } from "components/front/APP/Navigation/Tooltip"

export type PreStaySendStatusProps = {
  preStay: PreStay
}

export const PreStaySendStatus = ({ preStay }: PreStaySendStatusProps) => {


  switch (preStay?.sendStatus) {
    case PreStaySendStatusEnum.PRE_STAY_SEND_STATUS_HOLD: {
      return preStay?.reason ? <Tooltip label={preStay?.reason} top={-150}>
        <span className="badge badge-sm badge-warning">NON VALIDE</span>
      </Tooltip>
        : <span className="badge badge-sm badge-warning">NON VALIDE</span>

    }

    case PreStaySendStatusEnum.PRE_STAY_SEND_STATUS_TODO: {
      return <span className="badge badge-sm badge-success">VALIDE</span>
    }

    default: {
      return <span className="badge badge-sm badge-dard">{preStay?.sendStatus}</span>
    }

  }


}
import gql from "graphql-tag";

export default gql`

  fragment PriceRateTypeFragment on PriceRateType {
    id
    code
    isBreakfastIncluded
    isRefundable
    isGiftBox
    isGroup
    mustGenerateConfirmation
    isIncludedInConfirmationTotal
    hasSpa
    hasFood
    hasDrink
    hasService
    isFree
    canBeSelectedInProposition
    labelFr
    labelEn
    descFr
    descEn
    priceRateCancelConditionId
    conditionsFR
    conditionsEN
    # conditionsCancelFR
    # conditionsCancelEN
  }

`
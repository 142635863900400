import { useQuery, useSubscription } from "@apollo/react-hooks"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat"
// import hotelList from "graphql/PMS/Hotel/query/hotelList"
import hotelListQuery from "graphql/PMS/Hotel/query/hotelList"
import hotelUpdateGql from "graphql/PMS/Hotel/subscription/hotelUpdate.gql"
import { Hotel } from "model"
import React from "react"

export const HotelStatusWidget = () => {

  const { data } = useQuery(hotelListQuery, {
    fetchPolicy: "cache-and-network",
  })

  useSubscription(hotelUpdateGql)


  const hotelList = data?.list?.filter((hotel: Hotel) => hotel.activeFetcher)
  const hasHotel = hotelList && hotelList?.length > 0

  return hasHotel ? <div className="card border-dark">
    <div className="card-header bg-dark text-white">Etat de la synchro PMS</div>
    <div className="card-body">
      {hasHotel && hotelList?.map((hotel: any) => {

        const lastFetchTime = hotel?.lastFetch && new Date(hotel?.lastFetch).getTime()
        const nowMinus5Minutes = Date.now() - (10 * 60 * 1000);
        const hasActiveFetcher = lastFetchTime && lastFetchTime > nowMinus5Minutes

        return <div key={`${hotel?.id}`}>
          <span>{hasActiveFetcher && <span className="icon-check"></span>}</span>
          <span>{!hasActiveFetcher && <span className="icon-warning-sign"></span>}</span>
          <span style={{ paddingLeft: 10 }}>{hotel?.name}</span>
          <span><span> <DateTimeFormat value={new Date(hotel?.lastFetch)} showUTC={false} /></span></span>
        </div>
      })}
    </div>
  </div> : <></>
}